import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import history from '../../../history';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import moment from 'moment';

class NewRequest extends Component {

   state = {
      slots: [],
      step: 0,
      total: 0,
      animalType: null,
      makeUp: [],
      additional : "",
      Cattle: [  
         {  
            "name":"Steer",
            "quantity": 0,
            "fatClasses":[  
               {  
                  "name":"1",
                  "quantity":"0"
               },
               {  
                  "name":"2",
                  "quantity":"0"
               },
               {  
                  "name":"3",
                  "quantity":"0"
               },
               {  
                  "name":"4L",
                  "quantity":"0"
               },
               {  
                  "name":"4H",
                  "quantity":"0"
               },
               {  
                  "name":"5L",
                  "quantity":"0"
               },
               {  
                  "name":"5H",
                  "quantity":"0"
               }
            ]
         },
         {  
            "name":"Heifer",
            "quantity": 0,
            "fatClasses":[  
               {  
                  "name":"1",
                  "quantity":"0"
               },
               {  
                  "name":"2",
                  "quantity":"0"
               },
               {  
                  "name":"3",
                  "quantity":"0"
               },
               {  
                  "name":"4L",
                  "quantity":"0"
               },
               {  
                  "name":"4H",
                  "quantity":"0"
               },
               {  
                  "name":"5L",
                  "quantity":"0"
               },
               {  
                  "name":"5H",
                  "quantity":"0"
               }
            ]
         },
         {  
            "name":"SYB",
            "quantity": 0,
            "fatClasses":[  
               {  
                  "name":"1",
                  "quantity":"0"
               },
               {  
                  "name":"2",
                  "quantity":"0"
               },
               {  
                  "name":"3",
                  "quantity":"0"
               },
               {  
                  "name":"4L",
                  "quantity":"0"
               },
               {  
                  "name":"4H",
                  "quantity":"0"
               },
               {  
                  "name":"5L",
                  "quantity":"0"
               },
               {  
                  "name":"5H",
                  "quantity":"0"
               }
            ]
         },
         {  
            "name":"OTM",
            "quantity": 0,
            "fatClasses":[  
               {  
                  "name":"1",
                  "quantity":"0"
               },
               {  
                  "name":"2",
                  "quantity":"0"
               },
               {  
                  "name":"3",
                  "quantity":"0"
               },
               {  
                  "name":"4L",
                  "quantity":"0"
               },
               {  
                  "name":"4H",
                  "quantity":"0"
               },
               {  
                  "name":"5L",
                  "quantity":"0"
               },
               {  
                  "name":"5H",
                  "quantity":"0"
               }
            ]
         },
         {  
            "name":"OTM-Bull",
            "quantity": 0,
            "fatClasses":[  
               {  
                  "name":"1",
                  "quantity":"0"
               },
               {  
                  "name":"2",
                  "quantity":"0"
               },
               {  
                  "name":"3",
                  "quantity":"0"
               },
               {  
                  "name":"4L",
                  "quantity":"0"
               },
               {  
                  "name":"4H",
                  "quantity":"0"
               },
               {  
                  "name":"5L",
                  "quantity":"0"
               },
               {  
                  "name":"5H",
                  "quantity":"0"
               }
            ]
         }
      ],
      Sheep: [
         {  
               "name":"Lamb",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3L",
                     "quantity":"0"
                  },
                  {  
                     "name":"3H",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5",
                     "quantity":"0"
                  }
               ]   
         },
         {  
               "name":"Hogget",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3L",
                     "quantity":"0"
                  },
                  {  
                     "name":"3H",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5",
                     "quantity":"0"
                  }
               ]   
         },
         {  
               "name":"OCS",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3L",
                     "quantity":"0"
                  },
                  {  
                     "name":"3H",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5",
                     "quantity":"0"
                  }
               ]   
         },
         {  
               "name":"Ewe",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3L",
                     "quantity":"0"
                  },
                  {  
                     "name":"3H",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5",
                     "quantity":"0"
                  }
               ]   
         },
         {  
               "name":"Ram",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3L",
                     "quantity":"0"
                  },
                  {  
                     "name":"3H",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5",
                     "quantity":"0"
                  }
               ]   
         },
         {  
               "name":"Loaghtan",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3L",
                     "quantity":"0"
                  },
                  {  
                     "name":"3H",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5",
                     "quantity":"0"
                  }
               ]   
         },
      ],
      Pigs: [
         {  
               "name":"Pig",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"All",
                     "quantity":"0"
                  },
               ]   
         },
         {  
               "name":"Sow",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"All",
                     "quantity":"0"
                  },
               ]   
         },
         {  
               "name":"Boar",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"All",
                     "quantity":"0"
                  },
               ]   
         },
      ]
   }

   loopSlots = (data) => {
      var slots = [];
      data.forEach((slot) => {
         var slot = this.props.context.getSlot(slot._id)
         slots.push(slot)
      })
      return Promise.all(slots);
   }

   next = (step) => {
      if(step === 0){

         if(this.state.animalType == null){
            return false
         }

         axios.get(this.props.context.api+"/slots", {
            headers: {
               Authorization: localStorage.getItem("token")
            }
         })
         .then((res) => {

            this.loopSlots(res.data)
            .then((res) => {
               this.setState({
                  slots: res,
                  step: 1
               })
            })
         })
         .catch((error) => {
            this.props.context.handleError(error)
         })
      }

      if(step === 1){
         var selected = false;
         this.state.slots.map((slot) => {
            if(slot.selected === true){
               selected = true;
            }
         })
         if(selected === true){
            var _type = this.state.animalType;
            var makeUp = this.state[_type];
            this.setState({
                  makeUp: makeUp,
                  step: 2
            })
         }
      }
      if(step === 2){
         this.setState({
            step: 3
         })
      }
   }

   back = (step) => {
      if(step === 1){
         this.setState({
               step: 0
         })
      }
      if(step === 2){
         this.setState({
               step: 1
         })
      }
   }

   setAnimalType = (e) => {
      this.setState({
         animalType: e.target.value
      })
   }

   selectSlot = (selectedSlot, e) => {

      if(e.target.classList[0] === "red"){
         return false
      }

      var slots = this.state.slots;
      slots.forEach(function(slot, slotIdx){
         if(selectedSlot === slotIdx){
            slot.selected = true
         } else {
            slot.selected = false;
         }
      })
      this.setState({
         slots: slots
      })
   }

   calculate = () => {
      var total = 0
      var makeUp = [];
      var types = document.querySelectorAll('.count');
      types.forEach(function(type){
         var group = {};
         group.name = type.lastChild.classList[1];
         var fatClasses = [];
         var count = 0;
         type.lastChild.childNodes.forEach(function(fatClass){
            var newFatClass = {};
            newFatClass.name = fatClass.firstChild.innerHTML;
            newFatClass.quantity = fatClass.lastChild.value;
            if(fatClass.lastChild.value == ''){
               count = parseInt(count) + 0;
            } else {
               count = parseInt(count) + parseInt(fatClass.lastChild.value);
            }
            fatClasses.push(newFatClass)
         })
         group.fatClasses = fatClasses;
         group.quantity = count;
         makeUp.push(group);
         total = parseInt(total) + parseInt(group.quantity);
      })
      this.setState({
         makeUp: makeUp,
         total: total
      })
   }

   confirm = () => {

      if(this.state.total < 1){
         alert('Livestock quantity cannot be zero.');
         return false;
      }

      var slotId = null;

      this.state.slots.map((slot) => {
         if(slot.selected === true){
            slotId = slot._id;
         }
      })

      axios.post(this.props.context.api+"/bookings/", {
         slotId: slotId,
         animalType: this.state.animalType,
         quantity: this.state.total,
         makeUp: JSON.stringify(this.state.makeUp),
         additionalNotes: this.state.additional
      },{
         headers: {
            Authorization: localStorage.getItem("token")
         }
      })
      .then((res) => {
         this.setState({
            loading: false
         })
         history.push('/user/');
      })
      .catch((error) => {
         this.props.context.handleError(error)
      })
   }

   sortByKeyAsc = (array, key) => {
      return array.sort(function (a, b) {
          var x = a[key]; var y = b[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
   }

   render() {

      var selectedSlot = "";
      var slots = [];
      this.state.slots.map((slot, slotIdx) => {
         if(new Date(slot.week) > new Date()){
            if(slot.selected === true){
               selectedSlot = slot;
            }
            slot["ref"] = slotIdx;
            slots.push(slot);
         }
      })
      
      slots = this.sortByKeyAsc(slots, "week")

      return (
         <>
               <div className="header">
                  <h2>New Booking Request</h2>
                  <div>
                     <NavLink to="/user/bookings/" className="btn red secondary">Cancel</NavLink>
                  </div>
               </div>

               {this.state.step == 0 ? 
                  <div className="form">
                     <h2>Select Animal Type:</h2>
                     <select defaultValue="null" value={this.state.animalType} onChange={this.setAnimalType.bind(this)}>
                           <option value="null" disabled>Select</option>
                           <option value="Cattle">Cattle</option>
                           <option value="Sheep">Sheep</option>
                           <option value="Pigs">Pigs</option>
                     </select>
                     <div className="buttons">
                           <button onClick={this.next.bind(this, 0)} className="btn">Next</button>
                     </div>
                  </div>
               : null}

               {this.state.step == 1 ? 
                  <div className="form">
                     <h2>Select Intake Week:</h2>
                     {slots.map((slot, slotIdx) => {
                        if(this.state.animalType === "Cattle"){
                           if(slot.cattleStatus < 50){
                              slot.status = "green";
                           } else if(slot.cattleStatus >= 50 && slot.cattleStatus < 90){
                              slot.status = "amber";
                           } else if(slot.cattleStatus >= 90){
                              slot.status = "red";
                           }
                        }
                        if(this.state.animalType === "Sheep"){
                           if(slot.sheepStatus < 60){
                              slot.status = "green";
                           } else if(slot.sheepStatus >= 60 && slot.sheepStatus < 95){
                              slot.status = "amber";
                           } else if(slot.sheepStatus >= 95){
                              slot.status = "red";
                           }
                        }
                        if(this.state.animalType === "Pigs"){
                           if(slot.pigsStatus < 50){
                              slot.status = "green";
                           } else if(slot.pigsStatus >= 50 && slot.pigsStatus < 90){
                              slot.status = "amber";
                           } else if(slot.pigsStatus >= 90){
                              slot.status = "red";
                           }
                        }
                        return (
                           slot.disabled == false ?
                              slot.status === "red" ?
                                 <div className={slot.status + " slot "}>
                                       <p>{moment(slot.week).format('ddd, Do MMMM YY')} - {moment(slot.week).add(4, 'days').format('ddd, Do MMMM YY')}</p>
                                 </div>
                              : 
                                 <div className={slot.status + " slot " + slot.selected} onClick={this.selectSlot.bind(this, slot.ref)}>
                                    <p>{moment(slot.week).format('ddd, Do MMMM YY')} - {moment(slot.week).add(4, 'days').format('ddd, Do MMMM YY')}</p>
                                 </div>   
                           : null
                        )
                     })}
                     <div className="buttons">
                           <button onClick={this.back.bind(this, 1)} className="btn red secondary">Back</button>
                           <button onClick={this.next.bind(this, 1)} className="btn">Next</button>
                     </div>
                  </div>
               : null}

               {this.state.step == 2 ? 
                  <>
                     {this.state.makeUp.map((type, typeIdx) => {
                           return (
                              <div className="form count" key={typeIdx}>
                                 <h2>{type.name}: {type.quantity}</h2>
                                 <p>Fat Classes:</p>
                                 <div className={"animal " + type.name}>
                                       { type.fatClasses.map((fatClass, fatClassIdx) => {
                                          return (
                                             <div key={fatClassIdx}>
                                                   <p>{fatClass.name}</p>
                                                   <input type="number" min="0" max="200" default="0" value={fatClass.quantity} onChange={this.calculate.bind(this)} />
                                             </div>
                                          )
                                       })}
                                 </div>
                              </div>
                           )
                     })}
                     <div className="form">
                           <p>Animal Type:</p>
                           <input type="text" value={this.state.animalType} disabled />
                           <p>Intake Week:</p>
                           <input type="text" value={moment(selectedSlot.week).format('ddd, Do MMMM YY') +" - "+ moment(selectedSlot.week).add(4, 'days').format('ddd, Do MMMM YY')} disabled />
                           <p>Total Livestock:</p>
                           <input type="number" value={this.state.total} disabled />
                           <p>Additional Notes:</p>
                           <textarea onChange={(e) => {this.setState({ additional: e.target.value })}}>{this.state.additional}</textarea>
                           <div className="buttons">
                              <button className="btn red secondary" onClick={this.back.bind(this, 2)}>Back</button>
                              <button className="btn" onClick={this.confirm.bind(this)}>Confirm &amp; Submit</button>
                           </div>
                     </div>
                  </>
               : null }
         </>
      );
   }
}

export default withContext(NewRequest);
