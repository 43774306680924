import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { withContext } from "../../../context/withContext";

import List from './list';
import Manage from './manage';

class Slots extends Component {

    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/user/bookings/" component={List} />
                    <Route exact path="/user/bookings/manage/:id" component={Manage} />
                    <Redirect to="/user/bookings/" />
                </Switch>
            </>
        );
    }
}

export default withContext(Slots);
