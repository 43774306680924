import React, { Component } from 'react';
import { withContext } from "../context/withContext";
import { NavLink } from 'react-router-dom';
import history from '../history';

import validator from 'validator';
class Login extends Component {

    state = {
        email: "",
        password: "",
        errors: {}
    }

    login = (e) => {

        e.preventDefault();

        let errors = {
            email: false,
            password: false
        }

        if (!validator.isEmail(this.state.email)){
            errors.email = true;
        }

        if (!validator.isLength(this.state.password, { min: 8 })){
            errors.password = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        this.props.context.login(this.state.email, this.state.password, e);
    }

    render() {

        if(this.props.context.tokenChecked == true){
            if(this.props.context.user.clearanceLevel === 1){
                history.push('/user/');
            } else if(this.props.context.user.clearanceLevel > 1){
                history.push('/admin/');
            }
        }

        return (
            <div className="login">
                <h2>IOM Meats Login</h2>
                <form>
                    <input className={this.state.errors.email && "error"} type="email" placeholder="Email Address" value={this.state.email} onChange={(e) => {this.setState({ email: e.target.value })}}></input>
                    <input className={this.state.errors.password && "error"} type="password" placeholder="Password" value={this.state.password} onChange={(e) => {this.setState({ password: e.target.value })}}></input>
                    <button className="btn" onClick={this.login.bind(this)}>Login</button>
                    <br/>
                    <NavLink to="/forgot-password/">Forgot Password?</NavLink>
                </form>
            </div>
        );
    }
}

export default withContext(Login);
