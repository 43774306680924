import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { withContext } from "../../../context/withContext";

import axios from 'axios';

class List extends Component {

    state = {
        users: []
    }

    componentDidMount(){
        axios.get(this.props.context.api+"/users", {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            var users = [];
            res.data.forEach( function(user){
                if(user.clearanceLevel === 1){
                    users.push(user);
                }
            })
            this.setState({
                users: users
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <>
                <div className="header">
                    <h2>Users</h2>
                    <NavLink to="/admin/users/create" className="btn">Create New User</NavLink>
                </div>
                <div className="list">
                    {this.state.users.length > 0 ?
                        this.state.users.map((user) => {
                            return (
                                <NavLink to={"/admin/users/manage/"+user._id}>{user.firstName} {user.lastName}</NavLink>
                            )
                        })
                    :
                        <p>There aren't any users to display.</p>
                    }
                </div>
            </>
        );
    }
}

export default withContext(List);
