import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import history from '../../../history';

import { withContext } from "../../../context/withContext";
import axios from 'axios';
import moment from 'moment';
class Create extends Component {

    state = {
        users: [],
        slots: [],
        userId: null,
        slotId: null,
        animalType: "Cattle",
        total: 0,
        makeUp: [],
        additiona: "",
        Cattle: [  
            {  
               "name":"Steer",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5L",
                     "quantity":"0"
                  },
                  {  
                     "name":"5H",
                     "quantity":"0"
                  }
               ]
            },
            {  
               "name":"Heifer",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5L",
                     "quantity":"0"
                  },
                  {  
                     "name":"5H",
                     "quantity":"0"
                  }
               ]
            },
            {  
               "name":"SYB",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5L",
                     "quantity":"0"
                  },
                  {  
                     "name":"5H",
                     "quantity":"0"
                  }
               ]
            },
            {  
               "name":"OTM",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5L",
                     "quantity":"0"
                  },
                  {  
                     "name":"5H",
                     "quantity":"0"
                  }
               ]
            },
            {  
               "name":"OTM-Bull",
               "quantity": 0,
               "fatClasses":[  
                  {  
                     "name":"1",
                     "quantity":"0"
                  },
                  {  
                     "name":"2",
                     "quantity":"0"
                  },
                  {  
                     "name":"3",
                     "quantity":"0"
                  },
                  {  
                     "name":"4L",
                     "quantity":"0"
                  },
                  {  
                     "name":"4H",
                     "quantity":"0"
                  },
                  {  
                     "name":"5L",
                     "quantity":"0"
                  },
                  {  
                     "name":"5H",
                     "quantity":"0"
                  }
               ]
            }
         ],
         Sheep: [
            {  
                  "name":"Lamb",
                  "quantity": 0,
                  "fatClasses":[  
                     {  
                        "name":"1",
                        "quantity":"0"
                     },
                     {  
                        "name":"2",
                        "quantity":"0"
                     },
                     {  
                        "name":"3L",
                        "quantity":"0"
                     },
                     {  
                        "name":"3H",
                        "quantity":"0"
                     },
                     {  
                        "name":"4L",
                        "quantity":"0"
                     },
                     {  
                        "name":"4H",
                        "quantity":"0"
                     },
                     {  
                        "name":"5",
                        "quantity":"0"
                     }
                  ]   
            },
            {  
                  "name":"Hogget",
                  "quantity": 0,
                  "fatClasses":[  
                     {  
                        "name":"1",
                        "quantity":"0"
                     },
                     {  
                        "name":"2",
                        "quantity":"0"
                     },
                     {  
                        "name":"3L",
                        "quantity":"0"
                     },
                     {  
                        "name":"3H",
                        "quantity":"0"
                     },
                     {  
                        "name":"4L",
                        "quantity":"0"
                     },
                     {  
                        "name":"4H",
                        "quantity":"0"
                     },
                     {  
                        "name":"5",
                        "quantity":"0"
                     }
                  ]   
            },
            {  
                  "name":"OCS",
                  "quantity": 0,
                  "fatClasses":[  
                     {  
                        "name":"1",
                        "quantity":"0"
                     },
                     {  
                        "name":"2",
                        "quantity":"0"
                     },
                     {  
                        "name":"3L",
                        "quantity":"0"
                     },
                     {  
                        "name":"3H",
                        "quantity":"0"
                     },
                     {  
                        "name":"4L",
                        "quantity":"0"
                     },
                     {  
                        "name":"4H",
                        "quantity":"0"
                     },
                     {  
                        "name":"5",
                        "quantity":"0"
                     }
                  ]   
            },
            {  
                  "name":"Ewe",
                  "quantity": 0,
                  "fatClasses":[  
                     {  
                        "name":"1",
                        "quantity":"0"
                     },
                     {  
                        "name":"2",
                        "quantity":"0"
                     },
                     {  
                        "name":"3L",
                        "quantity":"0"
                     },
                     {  
                        "name":"3H",
                        "quantity":"0"
                     },
                     {  
                        "name":"4L",
                        "quantity":"0"
                     },
                     {  
                        "name":"4H",
                        "quantity":"0"
                     },
                     {  
                        "name":"5",
                        "quantity":"0"
                     }
                  ]   
            },
            {  
                  "name":"Ram",
                  "quantity": 0,
                  "fatClasses":[  
                     {  
                        "name":"1",
                        "quantity":"0"
                     },
                     {  
                        "name":"2",
                        "quantity":"0"
                     },
                     {  
                        "name":"3L",
                        "quantity":"0"
                     },
                     {  
                        "name":"3H",
                        "quantity":"0"
                     },
                     {  
                        "name":"4L",
                        "quantity":"0"
                     },
                     {  
                        "name":"4H",
                        "quantity":"0"
                     },
                     {  
                        "name":"5",
                        "quantity":"0"
                     }
                  ]   
            },
            {  
                  "name":"Loaghtan",
                  "quantity": 0,
                  "fatClasses":[  
                     {  
                        "name":"1",
                        "quantity":"0"
                     },
                     {  
                        "name":"2",
                        "quantity":"0"
                     },
                     {  
                        "name":"3L",
                        "quantity":"0"
                     },
                     {  
                        "name":"3H",
                        "quantity":"0"
                     },
                     {  
                        "name":"4L",
                        "quantity":"0"
                     },
                     {  
                        "name":"4H",
                        "quantity":"0"
                     },
                     {  
                        "name":"5",
                        "quantity":"0"
                     }
                  ]   
            },
         ],
         Pigs: [
            {  
                  "name":"Pig",
                  "quantity": 0,
                  "fatClasses":[  
                     {  
                        "name":"All",
                        "quantity":"0"
                     },
                  ]   
            },
            {  
                  "name":"Sow",
                  "quantity": 0,
                  "fatClasses":[  
                     {  
                        "name":"All",
                        "quantity":"0"
                     },
                  ]   
            },
            {  
                  "name":"Boar",
                  "quantity": 0,
                  "fatClasses":[  
                     {  
                        "name":"All",
                        "quantity":"0"
                     },
                  ]   
            },
        ]
    }

    componentDidMount(){

        axios.get(this.props.context.api+"/users", {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            var users = [];
            res.data.forEach( function(user){
                if(user.clearanceLevel === 1){
                    users.push(user);
                }
            })
            this.setState({
                users: users
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })

        axios.get(this.props.context.api+"/slots/", {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                slots: res.data
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })

        this.setState({
            makeUp: this.state["Cattle"]
        })
    }

    calculate = () => {
        var total = 0
        var makeUp = [];
        var types = document.querySelectorAll('.count');
        types.forEach(function(type){
           var group = {};
           group.name = type.lastChild.classList[1];
           var fatClasses = [];
           var count = 0;
           type.lastChild.childNodes.forEach(function(fatClass){
               var newFatClass = {};
               newFatClass.name = fatClass.firstChild.innerHTML;
               newFatClass.quantity = fatClass.lastChild.value;
               if(fatClass.lastChild.value == ''){
                  count = parseInt(count) + 0;
               } else {
                  count = parseInt(count) + parseInt(fatClass.lastChild.value);
               }
               fatClasses.push(newFatClass)
           })
           group.fatClasses = fatClasses;
           group.quantity = count;
           makeUp.push(group);
           total = parseInt(total) + parseInt(group.quantity);
        })
        this.setState({
           makeUp: makeUp,
           total: total
        })
    }

     confirm = () => {

         if(this.state.total < 1){
            alert('Livestock quantity cannot be zero.');
            return false;
         }

        axios.post(this.props.context.api+"/bookings/", {
           userId: this.state.userId,
           slotId: this.state.slotId,
           animalType: this.state.animalType,
           quantity: this.state.total,
           makeUp: JSON.stringify(this.state.makeUp),
           additionalNotes: this.state.additional
        },{
           headers: {
              Authorization: localStorage.getItem("token")
           }
        })
        .then((res) => {
           this.setState({
              loading: false
           })
           history.push('/admin/booking-requests');
        })
        .catch((error) => {
           this.props.context.handleError(error)
        })
     }

    render() {
        return (
            <>
                <div className="header">
                    <h2>Create New Request</h2>
                    <NavLink to="/admin/booking-requests/" className="btn red">Cancel</NavLink>
                </div>
                <div className="form">
                    <p>User</p>
                    <select value={this.state.userId} onChange={(e) => (this.setState({ userId: e.target.value}))}>
                        <option selected disabled value="null">-- Please select a user --</option>
                        {this.state.users.map((user, userIdx) => {
                            return (
                                <option value={user._id}>{user.firstName + " " + user.lastName}</option>
                            )
                        })}
                    </select>
                    <p>Slot</p>
                    <select value={this.state.slotId} onChange={(e) => (this.setState({ slotId: e.target.value}))}>
                        <option selected disabled value="null">-- Please select a slot --</option>
                        {this.state.slots.map((slot, slotIdx) => {
                            return (
                                <option value={slot._id}>{moment(slot.week).format('ddd, Do MMMM YY')} - {moment(slot.week).add(4, 'days').format('ddd, Do MMMM YY')} {slot.disabled === true ? '(Disabled)' : null}</option>
                            )
                        })}
                    </select>
                    <p>Animal Type</p>
                    <select value={this.state.animalType} onChange={(e) => {this.setState({ animalType: e.target.value, makeUp: this.state[e.target.value] }, () => {console.log(this.state)})}}>
                        <option value="Cattle">Cattle</option>
                        <option value="Sheep">Sheep</option>
                        <option value="Pigs">Pigs</option>
                    </select>
                </div>

                {this.state.makeUp.map((type, typeIdx) => {
                    return (
                        <div className="form count" key={typeIdx}>
                            <h2>{type.name}: {type.quantity}</h2>
                            <p>Fat Classes:</p>
                            <div className={"animal " + type.name}>
                                { type.fatClasses.map((fatClass, fatClassIdx) => {
                                    return (
                                        <div key={fatClassIdx}>
                                            <p>{fatClass.name}</p>
                                            <input type="number" value={fatClass.quantity} onChange={this.calculate.bind(this)} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}

                <div className="form">
                    <p>Total Quantity</p>
                    <input type="number" value={this.state.total} disabled/>
                    <p>Additional Notes</p>
                    <textarea value={this.state.additional} onChange={(e) => {this.setState({ additional: e.target.value })}}></textarea>
                    <button className="btn" onClick={this.confirm.bind(this)}>Confirm Request</button>
                </div>
            </>
        );
    }
}

export default withContext(Create);
