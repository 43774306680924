import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import history from './history';

import App from './app/app';

import AppContext from "./context/contextProvider";

class Root extends Component {
    render() {
        return (
            <>
                <Router history={history}>
                    <Route path="" component={RootApp} />
                </Router>
            </>
        );
    }
}

class RootApp extends Component {
    render() {
        return (
            <AppContext>
                <App />
            </AppContext>
        )
    }
}

export default Root;
