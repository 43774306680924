import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import history from '../../../history';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import validator from 'validator';
class Create extends Component {

    state = {
        saveLoading: false,
        deleteLoading: false,
        message: null,
        errors: {}
    }

    componentDidMount(){
        axios.get(this.props.context.api+"/users/"+this.props.match.params.id, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                disabled: res.data.disabled,
                fullName: res.data.firstName +" "+ res.data.lastName,
                email: res.data.email,
                clearanceLevel: res.data.clearanceLevel,
                phone: res.data.phone
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    save = (e) => {

        e.preventDefault();

        let errors = {
            fullName: false,
            email: false,
            phone: false
        }

        if (!validator.isLength(this.state.fullName, {min: 1})){
            errors.fullName = true;
        }

        if (!validator.isEmail(this.state.email)){
            errors.email = true;
        }

        if (!validator.isNumeric(this.state.phone)){
            errors.phone = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        this.setState({
            saveLoading: true
        })

        axios.put(this.props.context.api+"/users/"+this.props.match.params.id,{
            email: this.state.email,
            firstName: this.state.fullName.split(' ')[0],
            lastName: this.state.fullName.split(' ')[1],
            clearanceLevel: this.state.clearanceLevel,
            phone: this.state.phone.replace(/ /g, "")
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                saveLoading: false,
                message: this.state.fullName +"'s details were updated"
            })
            setTimeout(() => {
                this.setState({
                    message: null
                })
            }, 3000)
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    delete = (e) => {
        this.setState({
            deleteLoading: true
        })

        axios.delete(this.props.context.api+"/users/"+this.props.match.params.id, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                deleteLoading: false,
            })
            history.push('/admin/admin-users');
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <>
                { this.state.message !== null ?
                    <div className="message">
                        <p>{this.state.message}</p>
                    </div>
                :  null}
                <div className="header">
                    <h2>{this.state.fullName}</h2>
                    <div>
                        <NavLink to="/admin/admin-users/" className="btn red secondary">Cancel</NavLink>
                    </div>
                </div>
                <div className="form">
                    <input className={this.state.errors.fullName === true ? "error" : null } type="text" placeholder="Full Name" value={this.state.fullName} onChange={(e) => {this.setState({ fullName: e.target.value })}} />
                    <input className={this.state.errors.email === true ? "error" : null } type="email" placeholder="Email Address" value={this.state.email} onChange={(e) => {this.setState({ email: e.target.value })}} />
                    <input className={this.state.errors.phone === true ? "error" : null } type="tel" placeholder="Phone Number" value={this.state.phone} onChange={(e) => {this.setState({ phone: e.target.value })}} />
                    <select value={this.state.clearanceLevel} onChange={(e) => {this.setState({ clearanceLevel: e.target.value}) }}>
                        <option value="2">Office (Low)</option>
                        <option value="3">Management (Mid)</option>
                        <option disabled>Super (High)</option>
                    </select>
                    {this.state.saveLoading === false ?
                        <button className="btn" onClick={this.save.bind(this)}>Save</button>
                    :
                        <button className="btn loading"><img src="/images/loading.gif" /></button>
                    }
                </div>
                <div className="single">
                    <p>Permanently Delete User Account</p>
                    {this.state.deleteLoading === false ?
                        <button className="btn red" onClick={this.delete.bind(this)}>Delete</button>
                    :
                        <button className="btn red loading"><img src="/images/loading.gif" /></button>
                    }
                </div>
            </>
        );
    }
}

export default withContext(Create);
