import React, { Component } from 'react';
import { withContext } from "../context/withContext";
import history from '../history';

import axios from 'axios';

class Register extends Component {

    state = {
        password: ""
    }

    componentDidMount(){

    }

    register = () => {
        axios.post(this.props.context.api+"/me/register/"+this.props.match.params.id, {
            password: this.state.password
        }, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            history.push('/login')
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <div className="login">
                <h2>Choose a password to finish setting up your account.</h2>
                <input type="password" placeholder="New Password" value={this.state.password} onChange={(e) => {this.setState({ password: e.target.value })}}></input>
                <button className="btn" onClick={this.register.bind(this, this.state.email, this.state.password)}>Complete Sign Up</button>
            </div>
        );
    }
}

export default withContext(Register);
