import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
class List extends Component {

    state = {
        bookings: []
    }

    componentDidMount(){

        axios.get(this.props.context.api+"/bookings", {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                bookings: res.data
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })

    }

    render() {
        return (
            <>
                <div className="header">
                    <h2>My Bookings</h2>
                </div>
                <div className="list">
                    {this.state.bookings.length > 0 ?
                        this.state.bookings.map((booking) => {
                            if(booking.status == 1){
                                var status = "Requested";
                            } else  if(booking.status == 2){
                                var status = "Confirmed";
                            } else  if(booking.status == 3){
                                var status = "Rejected";
                            } else  if(booking.status > 3){
                                var status = "Cancelled";
                            } else {
                                var status = "Error"
                            }
                            return (
                                <NavLink to={"/user/bookings/manage/"+booking._id}>
                                    <p>{booking.quantity} {booking.animalType}</p>
                                    <p>{status}</p>
                                </NavLink>
                            )
                        })
                    :
                        <p>There are no bookings to display.</p>
                    }
                </div>
            </>
        );
    }
}

export default withContext(List);
