import * as React from 'react';

import { Context } from '../context/context';

import history from '../history';

import axios from 'axios';
export default class AppContext extends React.Component {

    state = {

        api: process.env.REACT_APP_API,

        // Memory
        tokenChecked: false,
        token: null,
        user: {
            clearanceLevel: null,
            firstName: null,
            lastName: null,
            email: null
        },
        message: null,

        // Functions
        login: this.login.bind(this),
        checkToken: this.checkToken.bind(this),
        logout: this.logout.bind(this),
        setMessage: this.setMessage.bind(this),
        handleError: this.handleError.bind(this),
        getSlot: this.getSlot.bind(this)
    }

    /**
     * API call and res handling for login
     */

    login(email, password, e){

        e.preventDefault();

        if(email && password){
            axios.post(process.env.REACT_APP_API+"/auth/login",
            {
                email: email,
                password: password
            })
            .then((res) => {
                localStorage.setItem("token", res.data.token);
                this.setState({
                    token: res.data.token,
                    message: null
                }, () => {
                    axios.get(process.env.REACT_APP_API+"/me", {
                        headers: {
                            Authorization: this.state.token
                        }
                    })
                    .then((res2) => {
                        this.setState({
                            user: res2.data
                        }, () => {
                            window.location.reload();
                        })
                    })
                })
            })
            .catch((error) => {
                this.handleError(error)
            })
        } else {
            this.setState({
                message: "You must enter your username and password."
            })
        }
    }

    /**
     * Fetch token from local storage and try to login
     */

    checkToken(){
        var token = localStorage.getItem("token");
        if(token){
            axios.get(process.env.REACT_APP_API+"/me", {
                headers: {
                    Authorization: token
                }
            })
            .then((res) => {
                this.setState({
                    token: token,
                    user: res.data,
                    tokenChecked: true
                })
            })
            .catch((error) => {
                this.handleError(error)
            })
        } else {
            this.setState({
                tokenChecked: true
            })
        }
        
    }

    /**
     * Log out: remove token from storage, empty state and go to login
     */

    logout(){
        localStorage.removeItem("token");
        var user = {
            clearanceLevel: null,
            firstName: null,
            lastName: null,
            email: null
        }
        this.setState({
            token: null,
            user: user
        }, () => {
            history.push('/')
        })
    }

    /**
     * Handle error
     */

    handleError(error){
        if(error.response){
            if(error.response.status === 401){
                if(error.response.data.payload.message == "jwt expired"){
                    localStorage.removeItem("token")
                } else {
                    alert(error.response.data.payload.message)
                }
            } else if(error.response.status === 404){
                this.setState({
                    message: error.response.data.payload.message
                })
            } else if(error.response.status === 500){
                alert('Internal server error, please report the issue to tom@thomaspatrick.co')
            } else {
                console.log(error.response)
            }
        } else {
            alert('There was an unknown error');
        }
    }

    /**
     * setMessage
     */

    setMessage(message){
        this.setState({
            message: message
        })

        setTimeout(() => {
            this.setState({
                message: null
            })
        }, 10000)
    }

    /**
     * getSlot
     */

    pingSlot(url){
        return axios.get(url, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            return res.data;
        })
    }

    getSlotBookings(id){
        return axios.get(process.env.REACT_APP_API+"/bookings/bySlot/"+id, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            return res.data;
        })
    }

    getSlot(slotId){

        return this.pingSlot(process.env.REACT_APP_API+"/slots/"+slotId)
        .then((res) => {
            return this.getSlotBookings(res._id)
            .then((res2) => {

                var pendingCattle = 0;
                var pendingSheep = 0;
                var pendingPigs = 0;

                var allocatedCattle = 0;
                var allocatedSheep = 0;
                var allocatedPigs = 0;

                var bookings = res2;

                bookings.forEach((booking) => {
                    if(booking.status === 1){
                        if(booking.animalType === "Cattle"){
                            pendingCattle = pendingCattle + parseInt(booking.quantity);
                        } else if(booking.animalType === "Sheep"){
                            pendingSheep = pendingSheep + parseInt(booking.quantity);
                        } else if(booking.animalType === "Pigs"){
                            pendingPigs = pendingPigs + parseInt(booking.quantity);
                        }
                    } else if(booking.status === 2){
                        if(booking.animalType === "Cattle"){
                            allocatedCattle = allocatedCattle + parseInt(booking.quantity);
                        } else if(booking.animalType === "Sheep"){
                            allocatedSheep = allocatedSheep + parseInt(booking.quantity);
                        } else if(booking.animalType === "Pigs"){
                            allocatedPigs = allocatedPigs + parseInt(booking.quantity);
                        }
                    }
                })

                var slot = res;
                slot["pendingCattle"] = pendingCattle;
                slot["pendingSheep"] = pendingSheep;
                slot["pendingPigs"] = pendingPigs;
                slot["allocatedCattle"] = allocatedCattle;
                slot["allocatedSheep"] = allocatedSheep;
                slot["allocatedPigs"] = allocatedPigs;

                slot["cattleStatus"] = ((pendingCattle + allocatedCattle) / slot.cattle) * 100;
                slot["sheepStatus"] = ((pendingSheep + allocatedSheep) / slot.sheep) * 100;
                slot["pigsStatus"] = ((pendingPigs + allocatedPigs) / slot.pigs) * 100;


                if(isNaN(slot["cattleStatus"])){
                    slot["cattleStatus"] = 100;
                }
                if(isNaN(slot["sheepStatus"])){
                    slot["sheepStatus"] = 100;
                }
                if(isNaN(slot["pigsStatus"])){
                    slot["pigsStatus"] = 100;
                }

                return (slot);

            })
        })
    }


    // Don't touch anything below.

    render() {
        return (
            <Context.Provider value={{
                context: {
                    ...this.state
                }
            }}>
            {this.props.children}
            </Context.Provider>
        )
    }
}