import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import history from '../../history';

import { withContext } from "../../context/withContext";

import NewRequest from "./new-request/index";
import Bookings from "./bookings/index";
import Account from "./account/index"
class User extends Component {

    render() {

        if(this.props.context.tokenChecked == true){
            if(this.props.context.user.clearanceLevel === 1){
            } else {
                history.push('/login/');
            }
        }

        return (
            <>
                <div className="layout">
                    <div className="header">
                        <div>
                            <h1>IOM Meats Bookings</h1>
                        </div>
                        <div>
                            <p>{this.props.context.user.firstName} {this.props.context.user.lastName}</p>
                            <button className="btn secondary" onClick={this.props.context.logout.bind(this)}>Log Out</button>
                        </div>
                    </div>
                    <main>
                        <div className="nav">
                            <NavLink to="/user/new-request/">New Booking Request</NavLink>   
                            <NavLink to="/user/bookings/">My Bookings</NavLink>  
                            <NavLink to="/user/account/">My Account</NavLink>  
                        </div>
                        <div className="content">
                            <Switch>
                                <Route path="/user/new-request/" component={NewRequest} />
                                <Route path="/user/bookings/" component={Bookings} />
                                <Route path="/user/account/" component={Account} />
                                <Redirect to="/user/bookings/" />
                            </Switch>
                        </div>
                    </main>
                </div>
            </>
        );
    }
}

export default withContext(User);
