import React, { Component } from 'react';
import { withContext } from "../context/withContext";
import history from '../history';

import validator from 'validator';
import axios from 'axios';

class Forgot extends Component {

    state = {
        email: "",
        errors: {},
        sent: false
    }

    reset = (e) => {

        e.preventDefault();

        let errors = {
            email: false,
        }

        if (!validator.isEmail(this.state.email)){
            errors.email = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        axios.post(this.props.context.api+"/auth/forgotpassword", {
            email: this.state.email
        })
        .then((res) => {
            this.setState({
                sent: true
            })
        })

    }

    render() {

        return (
            <div className="login">
                <h2>Reset Password</h2>
                {this.state.sent === false ?
                    <>
                        <p>Enter your email address below.</p>
                        <form>
                            <input class={this.state.errors.email && "error"} type="email" placeholder="Email Address" value={this.state.email} onChange={(e) => {this.setState({ email: e.target.value })}}></input>
                            <button className="btn" onClick={this.reset.bind(this)}>Request Reset Link</button>
                        </form>
                    </>
                :
                    <>
                        <p>We've sent you an email with a link to reset your password.</p>
                    </>
                }
                
            </div>
        );
    }
}

export default withContext(Forgot);
