import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import history from '../../../history';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import validator from 'validator';

class Create extends Component {

    state = {
        loading: false,
        week: new Date(),
        cattle: 0,
        sheep: 0,
        pigs: 0,
        errors: {}
    }

    create = (e) => {

        e.preventDefault();

        let errors = {
            week: false,
            cattle: false,
            sheep: false,
            pigs: false
        }

        if (!validator.isAfter(new Date(this.state.week).toString())){
            errors.week = true;
        }

        if (!validator.isInt(this.state.cattle.toString())){
            errors.cattle = true;
        }

        if (!validator.isInt(this.state.sheep.toString())){
            errors.sheep = true;
        }

        if (!validator.isInt(this.state.pigs.toString())){
            errors.pigs = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        this.setState({
            loading: true
        })

        axios.post(this.props.context.api+"/slots/", {
            week: moment(new Date(this.state.week)).isoWeekday(1).format('MM/DD/YYYY'),
            cattle: this.state.cattle,
            sheep: this.state.sheep,
            pigs: this.state.pigs,
            disabled: false
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                loading: false
            })
            history.push('/admin/slots');
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <>
                <div className="header">
                    <h2>Release New Slot</h2>
                    <div> 
                        <NavLink to="/admin/slots/" className="btn red secondary">Cancel</NavLink>
                    </div>
                </div>
                <div class="form">
                    <p>Week Beginning:</p>
                    <DatePicker
                        selected={new Date(this.state.week)}
                        onChange={(date) => { this.setState({ week: moment(new Date(date)).isoWeekday(1).format('MM/DD/YYYY') }) }}
                        minDate={new Date()}
                        filterDate={this.isWeekday}
                        placeholderText="Select the Monday of the week"
                        className={this.state.errors.week === true ? "error" : null}
                        dateFormat="dd/MM/yyyy"
                    />
                    <p>Cattle:</p>
                    <input className={this.state.errors.cattle === true ? "error" : null} type="number" value={this.state.cattle} onChange={(e) => {this.setState({ cattle: e.target.value })}} />
                    <p>Sheep:</p>
                    <input className={this.state.errors.sheep === true ? "error" : null} type="number" value={this.state.sheep} onChange={(e) => {this.setState({ sheep: e.target.value })}} />
                    <p>Pigs:</p>
                    <input className={this.state.errors.pigs === true ? "error" : null} type="number" value={this.state.pigs} onChange={(e) => {this.setState({ pigs: e.target.value })}} />
                    {this.state.loading === false ?
                        <button className="btn" onClick={this.create.bind(this)}>Release Slot</button>
                    :
                        <button className="btn loading"><img src="/images/loading.gif" /></button>
                    }
                </div>
            </>
        );
    }
}

export default withContext(Create);
