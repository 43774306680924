import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import history from '../history';

import { withContext } from "../context/withContext";

import Register from './register';
import Login from './login';
import Forgot from './forgot';
import Reset from './reset';

import User from './user/index';
import Admin from './admin/index';
class App extends Component {

    componentDidMount(){
        this.props.context.checkToken()
    }

    render() {

        return (
            <>
                <Switch history={history}>
                    <Route exact path="/register/:id" component={Register} />
                    <Route path="/login/" component={Login} />
                    <Route path="/forgot-password/" component={Forgot} />
                    <Route path="/reset-password/:token" component={Reset} />
                    <Route path="/admin/" component={Admin} />
                    <Route path="/user/" component={User} />
                    <Redirect to="/login/" />
                </Switch>
                {this.props.context.message !== null ?
                    <div className="message">
                        <p>{this.props.context.message}</p>
                        <img src="/images/cross.svg" onClick={this.props.context.setMessage.bind(this, null)} />
                    </div> 
                : null }
            </>
        )        
    }
}

export default withContext(App);
