import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { withContext } from "../../../context/withContext";

import moment from 'moment';
import axios from 'axios';
class List extends Component {

    state = {
        slots: []
    }

    sortByKeyAsc = (array, key) => {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    componentDidMount(){
        axios.get(this.props.context.api+"/slots/", {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            var slots = [];

            res.data.map((slot) => {
                if(slot.disabled === false){
                    if(new Date(slot.week) > new Date()){
                        this.fetchBookings(slot._id).then((res) => {
                            slot["bookings"] = res;
                            slots.push(slot)
                            slots = this.sortByKeyAsc(slots, "week")
                            this.setState({
                                slots: slots
                            })
                        });
                    }
                }
            })            
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    fetchBookings = (slotId) => {
        
        return axios.get(this.props.context.api+"/bookings/bySlot/"+slotId+"/1", {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })

    }

    render() {
        return (
            <>
                <div className="header">
                    <h2>Booking Requests</h2>
                    <NavLink to="/admin/booking-requests/create" className="btn">Create Booking Request</NavLink>
                </div>
                {this.state.slots.map((slot) => {
                    return (
                        <>
                            <p>{moment(slot.week).format('ddd, Do MMMM YY')} - {moment(slot.week).add(4, 'days').format('ddd, Do MMMM YY')}</p>
                            <div className="list">
                                {slot.bookings.length > 0 ?
                                    slot.bookings.map((booking) => {
                                        return (
                                            <NavLink to={"/admin/booking-requests/manage/"+booking._id}>
                                                <p>{booking.quantity} {booking.animalType}</p>
                                            </NavLink>
                                        )
                                    })
                                : 
                                    <p>There are no requests for this slot.</p>
                                }
                            </div>
                        </>
                    )
                })}
                { this.state.slots.length === 0 ?
                    <div className="list">
                        <p>There aren't any booking requests to display.</p>
                    </div>
                : null }
            </>
        );
    }
}

export default withContext(List);
