import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import moment from 'moment';

class Manage extends Component {

    state = {
        dateCreated: "",
        user: {
            name: "",
            email: ""
        },
        lastEdited: "",
        lastEditedBy: "Not Applicable",
        slot: [],
        animalType: "",
        quantity: 0,
        makeUp: []
    }

    componentDidMount(){

        axios.get(this.props.context.api+"/bookings/"+this.props.match.params.id, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                status: res.data.status,
                dateCreated: res.data.dateCreated,
                makeUp: JSON.parse(res.data.makeUp),
                quantity: res.data.quantity,
                animalType: res.data.animalType,
                additionalNotes: res.data.additionalNotes
            })
            axios.get(this.props.context.api+"/users/"+res.data.userId, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            })
            .then((res) => {
                var user = {
                    name: res.data.firstName+" "+res.data.lastName,
                    email: res.data.email
                }
                this.setState({
                    user: user
                })
            })
            .catch((error) => {
                this.props.context.handleError(error)
            })

            this.props.context.getSlot(res.data.slotId)
            .then((res) => {
                console.log(res)
                this.setState({
                    slot: res
                })
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })

    }

    render() {
        return (
            <>
                <div className="header">
                    <h2>Booking Request</h2>
                    <div>
                        <NavLink to={"/admin/booking-requests/manage/"+this.props.match.params.id+"/accept"} className="btn primary">Accept Booking</NavLink>
                        <NavLink to={"/admin/booking-requests/manage/"+this.props.match.params.id+"/reject"} className="btn red">Reject Booking</NavLink>
                        <NavLink to="/admin/booking-requests/" className="btn red secondary">Back</NavLink>
                    </div>
                </div>
                <div className="form">
                    <h2>User Details:</h2>
                    <p>Requested By:</p>
                    <input type="text" value={this.state.user.name} disabled />
                    <p>Email Address:</p>
                    <input type="text" value={this.state.user.email} disabled />
                    <p>Request Created At:</p>
                    <input type="text" value={moment(this.state.dateCreated).format('LLL')} disabled/>
                </div>
                <div className="form">
                    <h2>Admin Details:</h2>
                    <p>Last Edited At:</p>
                    <input type="text" value={moment(this.state.lastEdited).format('LLL')} disabled />
                    <p>Last Edited By:</p>
                    <input type="text" value={this.state.lastEditedBy} disabled />
                </div>
                <div className="form">
                    <h2>Slot Details:</h2>
                    <p>Week Beginning:</p>
                    <input type="text" value={moment(this.state.slot.week).format('Do MMMM YYYY')} disabled />
                    <p>Unallocated Cattle:</p>
                    <input type="number" value={this.state.slot.cattle - this.state.slot.allocatedCattle} disabled />
                    <p>Unallocated Sheep:</p>
                    <input type="number" value={this.state.slot.sheep - this.state.slot.allocatedSheep} disabled />
                    <p>Unallocated Pigs:</p>
                    <input type="number" value={this.state.slot.pigs - this.state.slot.allocatedPigs} disabled />
                </div>
                {this.state.makeUp.map((type, typeIdx) => {
                    return (
                        type.quantity > 0 ?
                            <div className="form count" key={typeIdx}>
                                <h2>{type.name}: {type.quantity}</h2>
                                <p>Fat Classes:</p>
                                <div className={"animal " + type.name}>
                                    { type.fatClasses.map((fatClass, fatClassIdx) => {
                                        return (
                                            <div key={fatClassIdx}>
                                                <p>{fatClass.name}</p>
                                                <input type="number" value={fatClass.quantity} disabled />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        : null
                    )
                })}
                <div className="form">
                    <h2>Summary:</h2>
                    <p>Livestock Type:</p>
                    <input type="text" value={this.state.animalType} disabled />
                    <p>Total Livestock:</p>
                    <input type="text" value={this.state.quantity} disabled />
                    <p>Additional Notes:</p>
                    <textarea placeholder="Additional Notes" disabled value={this.state.additionalNotes}></textarea>
                </div>
            </>
        );
    }
}

export default withContext(Manage);
