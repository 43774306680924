import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";
import validator from 'validator';
class Manage extends Component {

    state = {
        saveLoading: false,
        deactivateLoading: false,
        week: "2019-01-01T00:00:00.000+00:00",
        lastEdited: "2019-01-01T00:00:00.000+00:00",
        lastEditedBy: "Tom Gough",
        message: null,
        errors: {}
    }

    fetchUser = (id, booking) => {

        return axios.get(this.props.context.api+"/users/"+id, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            booking.user = res.data;
            return booking;
        })
        .catch((error) => console.log(error));
    }

    componentDidMount(){

        axios.get(this.props.context.api+"/slots/"+this.props.match.params.id, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.props.context.getSlot(res.data._id)
            .then((res) => {
                this.setState({
                    week: res.week,
                    cattle: res.cattle,
                    sheep: res.sheep,
                    pigs: res.pigs,
                    dateCreated: res.created,
                    disabled: res.disabled,
                    pendingCattle: res.pendingCattle,
                    pendingSheep: res.pendingSheep,
                    pendingPigs: res.pendingPigs,
                    allocatedCattle: res.allocatedCattle,
                    allocatedSheep: res.allocatedSheep,
                    allocatedPigs: res.allocatedPigs
                })
                axios.get(this.props.context.api+"/users/"+res.actionedBy, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                })
                .then((res) => {
                    this.setState({
                        actionedBy: res.data.firstName+" "+res.data.lastName
                    })
                })
                .catch((error) => {
                    this.props.context.handleError(error)
                })
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })


        axios.get(this.props.context.api+"/bookings/bySlot/"+this.props.match.params.id+"/2" , {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {

            let bookings = res.data;

            let promises = [];

            res.data.forEach((booking) => {
                promises.push(this.fetchUser(booking.userId, booking))
            })

            Promise.all(promises).then((res) => {
                const csvData = [
                    ["User", "Email Address", "Request Created At", "Animal Type", "Total Quanitity", "Additional Notes"]
                ]

                bookings.forEach((booking) => {
                    csvData.push([
                        booking.user.firstName + " "+booking.user.lastName, booking.user.email, booking.dateCreated, booking.animalType, booking.quantity, booking.additionNotes
                    ])
                })

                this.setState({
                    filename: moment(this.state.week).format('Do MMMM YYYY'),
                    csvData: csvData
                }) 
            })
        })
        .catch((error) => {
            alert('Error loading data for spreadsheet');
        })
    }

    

    save = (e) => {

        e.preventDefault();

        let errors = {
            cattle: false,
            sheep: false,
            pigs: false
        }

        if (!validator.isInt(this.state.cattle.toString())){
            errors.cattle = true;
        }

        if (!validator.isInt(this.state.sheep.toString())){
            errors.sheep = true;
        }

        if (!validator.isInt(this.state.pigs.toString())){
            errors.pigs = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        this.setState({
            saveLoading: true
        })

        axios.put(this.props.context.api+"/slots/"+this.props.match.params.id,{
            cattle: this.state.cattle == null ? 0 : this.state.cattle,
            sheep: this.state.sheep == null ? 0 : this.state.sheep,
            pigs: this.state.pigs == null ? 0 : this.state.pigs,
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                saveLoading: false,
                message: "This slot's quotas were updated."
            })
            setTimeout(() => {
                this.setState({
                    message: null
                })
            }, 3000)
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    deactivate = () => {
        this.setState({
            deactivateLoading: true
        })

        axios.put(this.props.context.api+"/slots/"+this.props.match.params.id, {
            disabled: !this.state.disabled
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                deactivateLoading: false,
                disabled: !this.state.disabled
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <>
                <div className="header">
                    <h2>Slot: {moment(this.state.week).format('Do MMMM YYYY')}</h2>
                    <NavLink to="/admin/slots/" className="btn red secondary">Cancel</NavLink>
                </div>
                <div className="form">
                    <h2>Slot Details:</h2>
                    <p>Week Beginning:</p>
                    <input type="text" value={moment(this.state.week).format('Do MMMM YYYY')} disabled/>
                    <p>Date Created:</p>
                    <input type="text" value={moment(this.state.dateCreated).format('LLL')} disabled /> 
                    <p>Created By:</p>
                    <input type="text" value={this.state.actionedBy} disabled />
                </div>
                {this.state.message !== null ?
                    <div className="message">
                        <p>{this.state.message}</p>
                    </div>
                : null}
                <div class="form">
                    <h2>Quota Details:</h2>
                    <p>Cattle:</p>
                    <input className={this.state.errors.cattle === true ? "error" : null} type="number" value={this.state.cattle} onChange={(e) => {this.setState({ cattle: e.target.value })}} />
                    <p>Sheep:</p>
                    <input className={this.state.errors.sheep === true ? "error" : null} type="number" value={this.state.sheep} onChange={(e) => {this.setState({ sheep: e.target.value })}} />
                    <p>Pigs:</p>
                    <input className={this.state.errors.pigs === true ? "error" : null} type="number" value={this.state.pigs} onChange={(e) => {this.setState({ pigs: e.target.value })}} />
                    {this.state.saveLoading === false ?
                        <button className="btn" onClick={this.save.bind(this)}>Update Quotas</button>
                    :
                        <button className="btn loading"><img src="/images/loading.gif" /></button>
                    }
                </div> 
                <div class="form">
                    <h2>Pending Approval:</h2>
                    <p>Pending Cattle:</p>
                    <input type="number" value={this.state.pendingCattle} disabled />
                    <p>Pending Sheep:</p>
                    <input type="number" value={this.state.pendingSheep} disabled />
                    <p>Pending Pigs:</p>
                    <input type="number" value={this.state.pendingPigs} disabled />
                </div>
                <div class="form">
                    <h2>Approved Bookings:</h2>
                    <p>Approved Cattle:</p>
                    <input type="number" value={this.state.allocatedCattle} disabled />
                    <p>Approved Sheep:</p>
                    <input type="number" value={this.state.allocatedSheep} disabled />
                    <p>Approved Pigs:</p>
                    <input type="number" value={this.state.allocatedPigs} disabled />
                </div>    
                {this.state.disabled === true ?
                    <div className="single">
                        <p>Re-activate Slot</p>
                        {this.state.deactivateLoading === false ?
                            <button className="btn" onClick={this.deactivate.bind(this)}>Activate</button>
                        :
                            <button className="btn loading"><img src="/images/loading.gif" /></button>
                        }
                    </div>
                :
                    <div className="single">
                        <p>Disable Slot</p>
                        {this.state.deactivateLoading === false ?
                            <button className="btn red" onClick={this.deactivate.bind(this)}>Disable</button>
                        :
                            <button className="btn red loading"><img src="/images/loading.gif" /></button>
                        }
                    </div>
                }   
                <div className="single">
                    <p>Download Spreadsheet</p>
                    {this.state.filename &&
                        <CSVLink
                            data={this.state.csvData}
                            filename={"Bookings "+this.state.filename+".csv"}
                            className="btn secondary"
                            target="_blank"
                        >
                            Download
                        </CSVLink>
                    }
                </div>   
            </>
        );
    }
}

export default withContext(Manage);
