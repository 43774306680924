import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import history from '../../../history';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import moment from 'moment';

class Reject extends Component {

    state = {
        message: "---"
    }

    reject = () => {
        axios.put(this.props.context.api+"/bookings/"+this.props.match.params.id+"/cancel", {
            message: this.state.message,
        }, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            history.push('/admin/booking-requests/')
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <>
                <div className="header">
                    <h2>Reject Booking Request</h2>
                    <div>
                        <NavLink to={"/admin/booking-requests/manage"+this.props.match.params.id} className="btn red secondary">Back</NavLink>
                    </div>
                </div>
                <div className="form">
                    <p>Add message to email:</p>
                    <textarea placeholder="Message" value={this.state.message} onChange={(e) => {this.setState({ message: e.target.value })}}></textarea>
                    <button className="btn red" onClick={this.reject.bind(this)}>Reject Booking</button>
                </div>
            </>
        );
    }
}

export default withContext(Reject);
