import React, { Component } from 'react';
import { withContext } from "../context/withContext";
import history from '../history';

import validator from 'validator';
import axios from 'axios';

class Reset extends Component {

    state = {
        token: "",
        password: "",
        errors: {}
    }

    componentDidMount(){
        axios.get(this.props.context.api+"/auth/passwordreset/"+this.props.match.params.token)
        .then((res) => {
            if(res.data.valid === true){
                this.setState({
                    token: this.props.match.params.token
                })
            } else {
                history.push('/login/');
            }   
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    reset = (e) => {

        e.preventDefault();

        let errors = {
            password: false
        }

        if (!validator.isLength(this.state.password, { min: 8 })){
            errors.password = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        axios.put(this.props.context.api+"/auth/passwordreset/"+this.state.token, {
            newPassword: this.state.password
        })
        .then((res) => {
            history.push('/login')
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <div className="login">
                <h2>Enter your new password.</h2>
                <input className={this.state.errors.password && "error"} type="password" placeholder="New Password" value={this.state.password} onChange={(e) => {this.setState({ password: e.target.value })}}></input>
                <button className="btn" onClick={this.reset.bind(this)}>Reset Password</button>
            </div>
        );
    }
}

export default withContext(Reset);