import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import validator from 'validator';
class Account extends Component {

    state = {
        detailsLoading: false,
        passwordLoading: false,
        emailLoading: false,
        message: null,
        name: "",
        phone: "",
        oldPassword1: "",
        newPassword: "",
        oldPassword2: "",
        newEmail: "",
        errors: {}
    }

    componentDidMount(){

        axios.get(this.props.context.api+"/me", {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            var name = res.data.firstName +" "+ res.data.lastName;
            this.setState({
                name: name,
                email: res.data.email,
                newEmail: res.data.email,
                phone: res.data.phone
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })

    }

    updateDetails = (e) => {

        e.preventDefault();

        let errors = {
            name: false,
            phone: false,
            oldPassword1: false,
            newPassword: false,
            oldPassword2: false,
            newEmail: false
        }

        if (!validator.isLength(this.state.name, {min: 7}) || !validator.contains(this.state.name, " ") || validator.contains(this.state.name, "  ")){
            errors.name = true;
        }

        if (!validator.isLength(this.state.phone, {min: 6}) || !validator.isNumeric(this.state.phone)){
            errors.phone = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        this.setState({
            detailsLoading: true
        })

        axios.put(this.props.context.api+"/me", {
            firstName: this.state.name.split(' ')[0],
            lastName: this.state.name.split(' ')[1],
            phone: this.state.phone.replace(/ /g, "")
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                detailsLoading: false,
                message: "Your details were updated."
            })
            setTimeout(() => {
                this.setState({
                    message: null
                })
            }, 3000)
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    changePassword = (e) => {

        e.preventDefault();

        let errors = {
            name: false,
            phone: false,
            oldPassword1: false,
            newPassword: false,
            oldPassword2: false,
            newEmail: false
        }

        if (!validator.isLength(this.state.oldPassword1, {min: 8})){
            errors.oldPassword1 = true;
        }

        if (!validator.isLength(this.state.newPassword, {min: 8})){
            errors.newPassword = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        this.setState({
            passwordLoading: true
        })

        axios.put(this.props.context.api+"/me/password", {
            password: this.state.oldPassword1,
            newPassword: this.state.newPassword
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                passwordLoading: false,
                oldPassword1: "",
                newPassword: "",
                message: "Your password was changed."
            })
            setTimeout(() => {
                this.setState({
                    message: null
                })
            }, 3000)
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    changeEmail = (e) => {

        e.preventDefault();

        let errors = {
            name: false,
            phone: false,
            oldPassword1: false,
            newPassword: false,
            oldPassword2: false,
            newEmail: false
        }

        if (!validator.isLength(this.state.oldPassword2, {min: 8})){
            errors.oldPassword2 = true;
        }

        if (!validator.isEmail(this.state.newEmail)){
            errors.newEmail = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        this.setState({
            emailLoading: true
        })

        axios.put(this.props.context.api+"/me/email", {
            password: this.state.oldPassword2,
            newEmail: this.state.newEmail
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                emailLoading: false,
                oldPassword2: "",
                message: "Your email address was changed."
            })
            setTimeout(() => {
                this.setState({
                    message: null
                })
            }, 3000)
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <> 
                {this.state.message !== null ?
                    <div className="message">
                        <p>{this.state.message}</p>
                    </div>
                : null}
                <div className="header">
                    <h2>Hey, {this.props.context.user.firstName} {this.props.context.user.lastName}! Update your details below.</h2>
                </div>
                <div className="form">
                    <input className={this.state.errors.name === true ? "error" : null } type="text" value={this.state.name} placeholder="Full Name" onChange={(e) => {this.setState({ name: e.target.value })}} />
                    <input className={this.state.errors.phone === true ? "error" : null } type="tel" value={this.state.phone} placeholder="Phone Number" onChange={(e) => {this.setState({ phone: e.target.value })}} />
                    {this.state.detailsLoading === false ?
                        <button className="btn" onClick={this.updateDetails.bind(this)}>Update Details</button>
                    :
                        <button className="btn"><img src="/images/loading.gif" /></button>
                    }
                </div>
                <div className="form">
                    <input className={this.state.errors.oldPassword1 === true ? "error" : null } type="password" value={this.state.oldPassword1} placeholder="Old Password" onChange={(e) => {this.setState({ oldPassword1: e.target.value })}} />
                    <input className={this.state.errors.newPassword === true ? "error" : null } type="password" value={this.state.newPassword} placeholder="New Password" onChange={(e) => {this.setState({ newPassword: e.target.value })}} />
                    {this.state.passwordLoading === false ?
                        <button className="btn" onClick={this.changePassword.bind(this)}>Change Password</button>
                    :
                        <button className="btn"><img src="/images/loading.gif" /></button>
                    }
                </div>
                <div className="form">
                    <input className={this.state.errors.oldPassword2 === true ? "error" : null } type="password" value={this.state.oldPassword2} placeholder="Old Password" onChange={(e) => {this.setState({ oldPassword2: e.target.value })}} />
                    <input className={this.state.errors.newEmail === true ? "error" : null } type="email" value={this.state.newEmail} placeholder="New Email Address" onChange={(e) => {this.setState({ newEmail: e.target.value })}} />
                    {this.state.emailLoading === false ?
                        <button className="btn" onClick={this.changeEmail.bind(this)}>Change Email</button>
                    :
                        <button className="btn"><img src="/images/loading.gif" /></button>
                    }
                </div>
            </>
        );
    }
}

export default withContext(Account);
