import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import history from '../../../history';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import validator from 'validator';
class Create extends Component {

    state = {
        loading: false,
        clearanceLevel: 2,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: "",
        errors: {}
    }

    create = (e) => {

        e.preventDefault();

        let errors = {
            firstName: false,
            lastName: false,
            email: false,
            password: false,
            phone: false
        }

        if (!validator.isLength(this.state.firstName, {min: 1})){
            errors.firstName = true;
        }

        if (!validator.isLength(this.state.lastName, {min: 1})){
            errors.lastName = true;
        }

        if (!validator.isEmail(this.state.email)){
            errors.email = true;
        }

        if (!validator.isLength(this.state.password, {min: 8})){
            errors.password = true;
        }

        if (!validator.isNumeric(this.state.phone)){
            errors.phone = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        this.setState({
            loading: true
        })

        axios.post(this.props.context.api+"/users", {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            phone: this.state.phone.replace(/ /g, ""),
            clearanceLevel: this.state.clearanceLevel,
            disabled: false,
            registered: true
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                loading: false
            })
            history.push('/admin/admin-users');
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <>
                <div className="header">
                    <h2>Create New Admin</h2>
                    <div>
                        <NavLink to="/admin/admin-users/" className="btn red secondary">Cancel</NavLink>
                    </div>
                </div>
                <div className="form">
                    <input className={this.state.errors.firstName === true ? "error" : null } type="text" placeholder="First Name" value={this.state.firstName} onChange={(e) => {this.setState({ firstName: e.target.value })}}/>
                    <input className={this.state.errors.lastName === true ? "error" : null } type="text" placeholder="Last Name" value={this.state.lastName} onChange={(e) => {this.setState({ lastName: e.target.value })}}/>
                    <input className={this.state.errors.email === true ? "error" : null } type="email" placeholder="Email Address" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value})}/>
                    <input className={this.state.errors.password === true ? "error" : null } type="password" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value})} />
                    <input className={this.state.errors.phone === true ? "error" : null } type="tel" placeholder="Phone Number" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value})}/>
                    <select value={this.state.clearanceLevel} onChange={(e) => {this.setState({ clearanceLevel: e.target.value}) }}>
                        <option value="2">Office (Low)</option>
                        <option value="3">Management (Mid)</option>
                        <option disabled>Super (High)</option>
                    </select>
                    {this.state.loading === false ?
                        <button className="btn" onClick={this.create.bind(this)}>Create Admin</button>
                    :
                        <button className="btn loading"><img src="/images/loading.gif" /></button>
                    }
                </div>
            </>
        );
    }
}

export default withContext(Create);
