import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import history from '../../../history';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import moment from 'moment';

class Accept extends Component {

    state = {
        dateCreated: "2019-01-01T00:00:00.000+00:00",
        user: {
            name: "John Deere",
            email: "john.d@gmail.com"
        },
        lastEdited: "2019-01-01T00:00:00.000+00:00",
        lastEditedBy: "Tom Gough",
        slot: [],
        animalType: "",
        quantity: 0,
        message: "---",
        makeUp: []
    }

    componentDidMount(){

        axios.get(this.props.context.api+"/bookings/"+this.props.match.params.id, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            console.log(res.data)
            this.setState({
                status: res.data.status,
                dateCreated: res.data.dateCreated,
                makeUp: JSON.parse(res.data.makeUp),
                quantity: res.data.quantity,
                animalType: res.data.animalType,
            })
            axios.get(this.props.context.api+"/users/"+res.data.userId, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            })
            .then((res) => {
                var user = {
                    name: res.data.firstName+" "+res.data.lastName,
                    email: res.data.email
                }
                this.setState({
                    user: user
                })
            })
            .catch((error) => {
                this.props.context.handleError(error)
            })

            this.props.context.getSlot(res.data.slotId)
            .then((res) => {
                console.log(res)
                this.setState({
                    slot: res
                })
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })

    }

    calculate = () => {
        var total = 0
        var makeUp = [];
        var types = document.querySelectorAll('.count');
        types.forEach(function(type){
           var group = {};
           group.name = type.lastChild.classList[1];
           var fatClasses = [];
           var count = 0;
           type.lastChild.childNodes.forEach(function(fatClass){
                 var newFatClass = {};
                 newFatClass.name = fatClass.firstChild.innerHTML;
                 newFatClass.quantity = fatClass.lastChild.value;
                 count = parseInt(count) + parseInt(fatClass.lastChild.value);
                 fatClasses.push(newFatClass)
           })
           group.fatClasses = fatClasses;
           group.quantity = count;
           makeUp.push(group);
           total = parseInt(total) + parseInt(group.quantity);
        })
        this.setState({
           makeUp: makeUp,
           quantity: total
        })
    }

    accept = () => {
        axios.put(this.props.context.api+"/bookings/"+this.props.match.params.id+"/amendaccept", {
            makeUp: JSON.stringify(this.state.makeUp),
            quantity: this.state.quantity,
            message: this.state.message
        }, {
            headers: {
                
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            history.push('/admin/booking-requests/')
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <>
                <div className="header">
                    <h2>Accept Booking Request</h2>
                    <div>
                        <NavLink to={"/admin/booking-requests/manage"+this.props.match.params.id} className="btn red secondary">Back</NavLink>
                    </div>
                </div>
                <p>Amend Quantities:</p>
                {this.state.makeUp.map((type, typeIdx) => {
                    return (
                        type.quantity > 0 ?
                            <div className="form count" key={typeIdx}>
                                <h2>{type.name}: {type.quantity}</h2>
                                <p>Fat Classes:</p>
                                <div className={"animal " + type.name}>
                                    { type.fatClasses.map((fatClass, fatClassIdx) => {
                                        return (
                                            <div key={fatClassIdx}>
                                                <p>{fatClass.name}</p>
                                                <input type="number" value={fatClass.quantity} onChange={this.calculate.bind(this)}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        : null
                    )
                })}
                <div className="form">
                    <p>Total Livestock:</p>
                    <input type="text" value={this.state.quantity} disabled />
                    <p>Add message to email:</p>
                    <textarea placeholder="Message" value={this.state.message} onChange={(e) => {this.setState({ message: e.target.value })}}></textarea>
                    <button className="btn primary" onClick={this.accept.bind(this)}>Accept Request</button>
                </div>
            </>
        );
    }
}

export default withContext(Accept);
