import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import moment from 'moment';

class List extends Component {

    state = {
        slots: []
    }

    componentDidMount(){
        axios.get(this.props.context.api+"/slots/", {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                slots: res.data
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    sortByKeyAsc = (array, key) => {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
     }
  
     render() {
        
        var slots = this.sortByKeyAsc(this.state.slots, "week")

        return (
            <>
                <div className="header">
                    <h2>Slots</h2>
                    <NavLink to="/admin/slots/create" className="btn">Release New Slot</NavLink>
                </div>
                <div className="list">
                    {slots.length > 0 ?
                        slots.map((slot) => {
                            return (
                                new Date(slot.week) > new Date() ?
                                    slot.disabled === true ?
                                        <NavLink to={"/admin/slots/manage/"+slot._id}>
                                            <p>{moment(slot.week).format('ddd, Do MMMM YY')} - {moment(slot.week).add(4, 'days').format('ddd, Do MMMM YY')} (Disabled)</p>
                                            <p>C{slot.cattle} - S{slot.sheep} - P{slot.pigs}</p>
                                        </NavLink>
                                    :
                                        <NavLink to={"/admin/slots/manage/"+slot._id}>
                                            <p>{moment(slot.week).format('ddd, Do MMMM YY')} - {moment(slot.week).add(4, 'days').format('ddd, Do MMMM YY')}</p>
                                            <p>C{slot.cattle} - S{slot.sheep} - P{slot.pigs}</p>
                                        </NavLink>
                                : null
                            )
                        })
                    :
                        <p>There aren't any slots to display.</p>
                    }
                </div>
            </>
        );
    }
}

export default withContext(List);
