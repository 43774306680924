import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { withContext } from "../../../context/withContext";

import axios from 'axios';

class List extends Component {

    state = {
        users: []
    }

    componentDidMount(){
        axios.get(this.props.context.api+"/users", {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            var users = [];
            res.data.forEach( function(user){
                if(user.clearanceLevel > 1){
                    users.push(user);
                }
            })
            this.setState({
                users: users
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {
        return (
            <>
                <div className="header">
                    <h2>Admin Users</h2>
                    <NavLink to="/admin/admin-users/create" className="btn">Create New Admin</NavLink>
                </div>
                <div className="list">
                    {this.state.users.map((user) => {
                        if(user.clearanceLevel == 2){
                            var level = "Office";
                        } else if(user.clearanceLevel == 3){
                            var level = "Management";
                        } else if(user.clearanceLevel == 4){
                            var level = "Super";
                        }
                        var you = "";
                        if(user._id === this.props.context.user._id){
                            you = "(You)";
                        } 
                        if(level !== "Super"){
                            return (
                                <NavLink to={"/admin/admin-users/manage/"+user._id}>
                                    <p>{user.firstName} {user.lastName}</p>
                                    <p>{level}</p>
                                </NavLink>
                            )
                        } else {
                            return (
                                <p>
                                    <p>{user.firstName} {user.lastName} {you}</p>
                                    <p>{level}</p>
                                </p>
                            )
                        }
                    })}
                </div>
            </>
        );
    }
}

export default withContext(List);
