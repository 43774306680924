import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import history from '../../history';

import { withContext } from "../../context/withContext";

import BookingRequests from "./bookingRequests/index";
import Bookings from "./bookings/index";
import Slots from "./slots/index";
import Users from "./users/index";
import AdminUsers from "./adminUsers/index";
import Account from "./account/index";
class Admin extends Component {

    render() {

        if(this.props.context.tokenChecked == true){
            if(this.props.context.user.clearanceLevel > 1){
            } else {
                history.push('/login/');
            }
        }
        return (
            <>
                <div className="layout">
                    <div className="header">
                        <div>
                            <h1>IOM Meats Bookings</h1>
                        </div>
                        <div>
                            <p>{this.props.context.user.firstName} {this.props.context.user.lastName}</p>
                            <button className="btn secondary" onClick={this.props.context.logout.bind(this)}>Log Out</button>
                        </div>
                    </div>
                    <main>
                        <div className="nav">
                            <p>Back Office</p>
                            <NavLink to="/admin/booking-requests/">Booking Requests</NavLink>
                            <NavLink to="/admin/bookings/">Confirmed Bookings</NavLink>
                            {this.props.context.user.clearanceLevel > 2 ?
                                <>
                                    <NavLink to="/admin/slots/">Slots</NavLink>
                                    <NavLink to="/admin/users/">Users</NavLink>
                                </>
                            : null }
                            {this.props.context.user.clearanceLevel == 4 ?
                                <NavLink to="/admin/admin-users/">Admin Users</NavLink>
                            : null }
                            <NavLink to="/admin/account/">My Account</NavLink>
                        </div>
                        <div className="content">
                            <Switch>
                                <Route path="/admin/booking-requests/" component={BookingRequests} />
                                <Route path="/admin/bookings/" component={Bookings} />
                                <Route path="/admin/slots/" component={Slots} />
                                <Route path="/admin/users/" component={Users} />
                                <Route path="/admin/admin-users/" component={AdminUsers} />
                                <Route path="/admin/account/" component={Account} />
                                <Redirect to="/admin/booking-requests/" />
                            </Switch>
                        </div>
                    </main>
                </div>
            </>
        );
    }
}

export default withContext(Admin);
