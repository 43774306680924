import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';

import { withContext } from "../../../context/withContext";

import List from './list';
import Create from './create';
import Manage from './manage';

class AdminUsers extends Component {

    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/admin/admin-users/" component={List} />
                    <Route exact path="/admin/admin-users/create/" component={Create} />
                    <Route exact path="/admin/admin-users/manage/:id" component={Manage} />
                    <Redirect to="/admin/admin-users/" />
                </Switch>
            </>
        );
    }
}

export default withContext(AdminUsers);
