import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { withContext } from "../../../context/withContext";

import List from './list';
import Create from './create';
import Manage from './manage';
import Reject from './reject';
import Accept from './accept';

class BookingRequests extends Component {

    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/admin/booking-requests/" component={List} />
                    <Route exact path="/admin/booking-requests/create/" component={Create} />
                    <Route exact path="/admin/booking-requests/manage/:id" component={Manage} />
                    <Route exact path="/admin/booking-requests/manage/:id/reject" component={Reject} />
                    <Route exact path="/admin/booking-requests/manage/:id/accept" component={Accept} />
                    <Redirect to="/admin/booking-requests/" />
                </Switch>
            </>
        );
    }
}

export default withContext(BookingRequests);
