import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import history from '../../../history'

import { withContext } from "../../../context/withContext";

import axios from 'axios';
import validator from 'validator';
class Create extends Component {

    state = {
        detailsLoading: false,
        passwordLoading: false,
        deleteLoading: false,
        activateLoading: false,
        fullName: "",
        email: "",
        phone: "",
        password: "",
        errors: {}
    }

    componentDidMount(){
        axios.get(this.props.context.api+"/users/"+this.props.match.params.id, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                disabled: res.data.disabled,
                fullName: res.data.firstName +" "+ res.data.lastName,
                email: res.data.email,
                phone: res.data.phone
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    updateDetails = (e) => {

        e.preventDefault();

        let errors = {
            fullName: false,
            email: false,
            phone: false,
            password: false
        }

        if (!validator.isLength(this.state.fullName, {min: 1})){
            errors.fullName = true;
        }

        if (!validator.isEmail(this.state.email)){
            errors.email = true;
        }

        if (!validator.isNumeric(this.state.phone)){
            errors.phone = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        this.setState({
            detailsLoading: true
        })

        axios.put(this.props.context.api+"/users/"+this.props.match.params.id,{
            email: this.state.email,
            firstName: this.state.fullName.split(' ')[0],
            lastName: this.state.fullName.split(' ')[1],
            phone: this.state.phone.replace(/ /g, "")
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                detailsLoading: false
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    changePassword = (e) => {

        e.preventDefault();

        let errors = {
            fullName: false,
            email: false,
            phone: false,
            password: false
        }

        if (!validator.isLength(this.state.password, {min: 8})){
            errors.password = true;
        }

        this.setState({ errors: errors })

        if (Object.keys(errors).some( key => { return errors[key] })){
            return false
        }

        this.setState({
            passwordLoading: true
        })

        axios.put(this.props.context.api+"/users/"+this.props.match.params.id, {
            password: this.state.password
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                passwordLoading: false,
                password: ""
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    activate = () => {
        this.setState({
            activateLoading: true
        })

        axios.put(this.props.context.api+"/users/"+this.props.match.params.id, {
            disabled: !this.state.disabled
        },{
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                activateLoading: false,
                disabled: !this.state.disabled
            })
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    delete = (e) => {
        this.setState({
            deleteLoading: true
        })

        axios.delete(this.props.context.api+"/users/"+this.props.match.params.id, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
        .then((res) => {
            this.setState({
                deleteLoading: false,
            })
            history.push('/admin/users');
        })
        .catch((error) => {
            this.props.context.handleError(error)
        })
    }

    render() {

        return (
            <>
                <div className="header">
                    <h2>User: {this.state.fullName}</h2>
                    <NavLink to="/admin/users/" className="btn red secondary">Cancel</NavLink>
                </div>
                <div className="form">
                    <input className={this.state.errors.fullName === true ? "error" : null} type="text" placeholder="Full Name" value={this.state.fullName} onChange={(e) => {this.setState({ fullName: e.target.value })}} />
                    <input className={this.state.errors.email === true ? "error" : null} type="email" placeholder="Email Address" value={this.state.email} onChange={(e) => {this.setState({ email: e.target.value })}} />
                    <input className={this.state.errors.phone === true ? "error" : null} type="tel'" placeholder="Phone Number" value={this.state.phone} onChange={(e) => {this.setState({ phone: e.target.value })}} />
                    {this.state.detailsLoading === false ?
                        <button className="btn" onClick={this.updateDetails.bind(this)}>Update Details</button>
                    :
                        <button className="btn"><img src="/images/loading.gif" /></button>
                    }
                </div>
                <div className="form">
                    <input className={this.state.errors.password === true ? "error" : null} type="password" placeholder="Password" value={this.state.password} onChange={(e) => {this.setState({password: e.target.value})}} />
                    {this.state.passwordLoading === false ?
                        <button className="btn" onClick={this.changePassword.bind(this)}>Change Password</button>
                    :
                        <button className="btn loading"><img src="/images/loading.gif" /></button>
                    }
                </div>
                {this.state.disabled === true ?
                    <div className="single">
                        <p>Re-activate User Account</p>
                        {this.state.activateLoading === false ?
                            <button className="btn" onClick={this.activate.bind(this)}>Activate</button>
                        :
                            <button className="btn loading"><img src="/images/loading.gif" /></button>
                        }
                    </div>
                :
                    <div className="single">
                        <p>Disable User Account</p>
                        {this.state.activateLoading === false ?
                            <button className="btn red" onClick={this.activate.bind(this)}>Disable</button>
                        :
                            <button className="btn red loading"><img src="/images/loading.gif" /></button>
                        }
                    </div>
                }
                <div className="single">
                        <p>Permanently Delete User Account</p>
                        {this.state.deleteLoading === false ?
                            <button className="btn red" onClick={this.delete.bind(this)}>Delete</button>
                        :
                            <button className="btn red loading"><img src="/images/loading.gif" /></button>
                        }
                    </div>
            </>
        );
    }
}

export default withContext(Create);
